import React from "react";
import { graphql } from "gatsby";

import logo from "../img/logo-icon.png";
import CardColumGenerator from "../components/CardGrid";
import Layout from "../components/Layout";

export default ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  return (
    <Layout>
      <section className="hero is-medium is-bold front-page-hero">
        <Hero />
        <Banner />
      </section>
      <section className="section">
        <Quote quoteText="Quand j’ai croisé la COULEUR ma vie est devenue en COULEURS" />
        <CenteredText
          subtitle={
            <React.Fragment>
              La couleur et la lumière sont le chemin vers l’univers. Elles
              réharmonisent les Chakras, ouvrent un plan de conscience,
              nourrissent le corps énergétique et alignent l’aspect vibratoire
              de chaque rayon de couleur en ramenant les énergies dispersées
              dans l’axe vertical qui nous relie à terre-ciel.
              <br />
              <br />
              Hommes, femmes, enfants, petits et grands tout le monde est
              concerné
              <br />
              <br />« mieux se connaître pour mieux vivre ».
            </React.Fragment>
          }
        />
      </section>

      <section id="services" className="section">
        <div className="container has-text-centered">
          <h3 className="title is-2">Services</h3>
          <CardColumGenerator cardArray={edges} />
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ServicePages {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/services/" } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 190) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Hero = () => (
  <div id="front-page-hero" className="hero-body">
    <div className="container has-text-centered">
      <img src={logo} alt="Logo" />
      <h1 id="hero-title" className="title fancy-text">
        Diazanne
      </h1>
      <h2 id="hero-subtitle" className="subtitle fancy-text">
        Massage Relaxant En couleurs
      </h2>
    </div>
  </div>
);

const CenteredText = ({ title, subtitle }) => (
  <div className="intro column is-8 is-offset-2">
    <h2 className="title">{title}</h2>
    <p className="subtitle">{subtitle}</p>
  </div>
);

const Quote = ({ quoteText }) => (
  <div className="intro column is-8 is-offset-2">
    <blockquote className="brooks">{quoteText}</blockquote>
  </div>
);

const Banner = () => (
  <a href="https://diazanne.eproshopping.fr/">
    <div className="box cta" id="boutique-banner">
      <p className="has-text-centered">
        <span className="tag is-primary">Nouveau</span> Cliquez ici pour visiter
        la boutique
      </p>
    </div>
  </a>
);
