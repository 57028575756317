import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const cardSorter = cardArray =>
  cardArray.reduce(
    (accumulator, currentValue, currentIndex, array) => {
      if (accumulator[accumulator.length - 1].length === 3)
        accumulator.push([]);
      accumulator[accumulator.length - 1].push(currentValue);
      return accumulator;
    },
    [[]]
  );

const CardColumGenerator = ({ cardArray, data }) => {
  const sortedCards = cardSorter(cardArray);
  return (
    <div className="features">
      {sortedCards.map(cardStack => {
        switch (cardStack.length) {
          case 1:
            return <OneCard cardArray={cardStack} />;
          case 2:
            return <TwoCards cardArray={cardStack} />;
          case 3:
            return <ThreeCards cardArray={cardStack} />;
          default:
            throw Error(
              "Something went wrong, length should be no larger than 3"
            );
        }
      })}
    </div>
  );
};

export default CardColumGenerator;

const ThreeCards = ({ cardArray }) => (
  <div className="columns">
    {cardArray.map(({ node }, index) => (
      <div key={`${node.frontmatter.title}-${index}`} className="column is-4">
        <Card {...node} />
      </div>
    ))}
  </div>
);

const TwoCards = ({ cardArray }) => (
  <div className="columns">
    <div className="column is-2"></div>
    {cardArray.map(({ node }) => (
      <div key={node.frontmatter.title} className="column is-4">
        <Card {...node} />
      </div>
    ))}
    <div className="column is-2"></div>
  </div>
);

const OneCard = ({
  cardArray: {
    0: { node }
  }
}) => (
  <div className="columns">
    <div className="column is-4"></div>
    <div className="column is-4">
      <Card {...node} />
    </div>
    <div className="column is-4"></div>
  </div>
);

const Card = ({ fields: { slug }, frontmatter }) => {
  const { image, title, description } = frontmatter;
  return (
    <Link to={slug} className="card is-shady">
      <div className="card-image has-text-centered">
        <i className="fa fa-empire"></i>
      </div>
      <div className="card-content">
        <div className="content">
          <div className="column">
            <Img fluid={image.childImageSharp.fluid} alt={title} />
          </div>
          <h4>{title}</h4>
          <p>{description}</p>
          <Link to={slug}>Lire la suite</Link>
        </div>
      </div>
    </Link>
  );
};
